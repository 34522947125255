require('bootstrap');
require('./plugins/bookmark');
require('./plugins/category');
require('./plugins/Chart');
require('./plugins/datepicker');
require('./plugins/formVerify');
require('./plugins/hashtagMove');
require('./plugins/hud');
require('./plugins/inputFile');
require('./plugins/modal');
require('./plugins/preload');
require('./plugins/privacyWarning');
require('./plugins/query');
require('./plugins/results');
require('./plugins/searchForm');
require('./plugins/smartmenus');
require('./plugins/tab');