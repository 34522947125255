var showModal = require('../modules/showModal');

var Chart = function(id) {
	this.id = id;
	this.chartType = '';
}
Chart.prototype.init = function(data) {
	this.chartType = data.type;
	
	if (typeof data.item != 'undefined' &&
			data.item.length > 0) {
		data.item.sort(function(a,b) {return a[0] - b[0];});
	}

	switch(this.chartType) {
		case 'month-chart':
			var $this = $('#'+ this.id);
			if (data.item.length != 0) {
				$this.parents('.chart').addClass('has-yearList');
				this.generateYearList(data);
			}
			break;
		case 'all-chart':
			this.generateAllChart(data);
			break;
		
		case 'distro-chart':
			this.generateDistroChart($('#chart'), data);
			break;

		default: break;
	}
	
	if (this.chartType !== 'all-chart') {
		this.generateDistroList(data);

		if (this.chartType !== 'distro-chart') {
			this.generateChart(data);
		}
	}
}
Chart.prototype.generateAllChart = function(data) {
	var _this = this;
	$('#chart').prepend('<div id="chartDom"></div>');
	var dom = document.getElementById('chartDom');
	dom.style.height = '60vh';
	var chartCanvas = echarts.init(dom, null, {renderer: 'canvas'});
	var arr = [];
	Object.keys(data.item).map(function (key) {
		arr.push(data.item[key][1]);
		arr.push(data.item[key][2]);
		arr.push(data.item[key][3]);
	});
	arr = arr.filter(Number);

	var option = {
		grid: [{
			top: '12%',
			width: '78%',
			height: '74%',
			left: 'center',
			containLabel: true
		}],
    dataZoom: [
			{
				type: 'slider',
				show: true,
				xAxisIndex: [0],
				bottom: '5%',
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			},
			{
				type: 'slider',
				show: true,
				yAxisIndex: [0],
				right: '5%',
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			}
		],
		tooltip: {
			padding: 0,
			backgroundColor: 'rgba(0,0,0,0)',
			borderRadius: '0',
			trigger: 'axis',

			formatter: function (params, ticket, callback) {
				var value = params[0].value || 'NO DATA';
				var list = '';

				Object.keys(params).map(function(key, index){
					list +=
						'<li>'+
							'<span class="is-sub-title">'+
								'<span class="is-color" style="';

					switch (params[key].seriesName) {
						case 'Search CPC':
							list += 'border: 2px solid ' + params[key].color;
							break;
						case 'Display CPC':
							list += 'border: 2px dashed ' + params[key].color;
							break;
						case 'Video CPV':
							list += 'border: 2px dotted ' + params[key].color;
							break;
					}

					list +=
							'"></span>' +
							params[key].seriesName +
							'</span>'+
							'<span class="is-value">'+ params[key].value +'</span>'+
						'</li>';
				})

				var res =
					'<div class="chart-tooltip">'+
						'<div class="is-title">' + params[0].name +'</div>' +
						'<div class="is-content">'+
							'<ul class="is-list">'+
								list
							'</ul>' +
						'</div>' +
					'</div>';
				return res;
			}
		},
		legend: {
			top: 0,
			left: 'center',
			data: [
				{
					name: 'Search CPC',
					icon: 'path://M 0 0 L 0 50 L 800 50 L 800 0 Z'
				},
				{
					name: 'Display CPC',
					icon: 'path://M 0 0 L 0 50 L 150 50 L 150 0 L 250 0 L 250 50 L 400 50 L 400 0 L 500 0 L 500 50 L 650 50 L 650 0 L 500 0 L 750 0 L 750 50 L 800 50 L 800 0 L 750 0 Z'
				},
				{
					name: 'Video CPV',
					icon: 'path://M 0 0 L 0 50 L 50 50 L 50 0 L 100 0 L 100 50 L 150 50 L 150 0 L 200 0 L 200 50 L 250 50 L 250 0 L 300 0 L 300 50 L 350 50 L 350 0 L 400 0 L 400 50 L 450 50 L 450 0 L 500 0 L 500 50 L 550 50 L 550 0 L 600 0 L 600 50 L 650 50 L 650 0 L 700 0 L 700 50 L 750 50 L 750 0 L 800 0 Z'
				}
			]
		}, 
		xAxis: {
			type: 'category',
			data: Object.keys(data.item).map(function (key) {
				return data.item[key][0];
			}),
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		yAxis: {
			scale: true,
			min: function(){
				var min = Math.min.apply( null, arr );
				return Math.floor(min*0.1)*10;
			},
			max: function(){
				var max = Math.max.apply( null, arr );
				return Math.ceil(max*0.1)*10;
			},
			type: 'value',
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				interval: 0,
				lineHeight: 200
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
			splitLine: {
				show: true
			},
			triggerEvent: false,
			offset: '10'
		},
		series: [
			{
				name: 'Search CPC',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][1];
				}),
				type: 'line',
				lineStyle: {
					normal: {
						color: 'rgba(65, 125, 187, 0.7)',
						width: 2,
						type: 'solid' 
					}
				},
				itemStyle: {
					normal: {
						color: 'rgba(65, 125, 187, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(65, 125, 187, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			},
			{
				name: 'Display CPC',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][2];
				}),
				type: 'line',
				lineStyle: {
					normal: {
						color: 'rgba(26, 147, 111, 0.7)',
						width: 2,
						type: 'dashed' 
					}
				},
				itemStyle: {
					normal: {
						color: 'rgba(26, 147, 111, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(26, 147, 111, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			},
			{
				name: 'Video CPV',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][3];
				}),
				type: 'line',
				lineStyle: {
					normal: {
						color: 'rgba(152, 59, 199, 0.7)',
						width: 2,
						type: 'dotted' 
					}
				},
				itemStyle: {
					normal: {
						color: 'rgba(152, 59, 199, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(152, 59, 199, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			}
		]
	};

	if (option && typeof option === "object") {
		chartCanvas.setOption(option, true);

		$(window).on('resize', function(){
			if(chartCanvas != null && chartCanvas != undefined){
					chartCanvas.resize();
			}
		});
	}
}
Chart.prototype.generateChart = function(data) {
	var _this = this;
	$('#'+this.id).prepend('<div id="chartDom"></div>');
	var dom = document.getElementById('chartDom');
	var chartHeight = null;
	var rowHeight = null;

	if (window.innerWidth>480) {
		rowHeight = 58;
	} else {
		rowHeight = 76;
	}

	if (Object.keys(data.item).length) {
		chartHeight += Object.keys(data.item).length * rowHeight;
	}

	dom.setAttribute('style', 'height: ' + chartHeight + 'px;');

	var chartCanvas = echarts.init(dom, null, {renderer: 'svg'});
	var name;

	if (data.campaign[1] == 'CTR' ||
			data.campaign[1] == 'VTR') {
		name = '%';
	} else {
		name = 'NT$';
	}

	var option = {
		grid: [{
			top: '0',
			width: '90%',
			height: chartHeight + 'px',
			left: 'center',
			containLabel: true
		}],
		tooltip: {
			padding: 0,
			backgroundColor: 'rgba(0,0,0,0)',
			borderRadius: '0',
			trigger: 'axis',
			position: function (pos, params, el, elRect, size) {
				var obj = {top: pos[1], right: '20vw'};
				return obj;
			},
			formatter: function (params, ticket, callback) {
				var sum, two, seven;
				
				if (data.campaign[1] == 'CTR' ||
						data.campaign[1] == 'VTR') {
					sum = params[1].data + '%';
					two = params[0].data[1] + '%';
					seven = params[0].data[2] + '%';
				} else {
					sum = 'NT$' + params[1].data;
					two = 'NT$' + params[0].data[1];
					seven = 'NT$' + params[0].data[2];
				}
				var res =
					'<div class="chart-tooltip">'+
						'<div class="is-title">' + params[0].name.split('\n').join('') + ' ' + params[0].seriesName + '</div>' +
						'<div class="is-content">'+
							'<ul class="is-list">'+
								'<li><span class="is-sub-title">'+params[1].seriesName+'</span><span class="is-value">'+sum+'</span></li>'+
								'<li><span class="is-sub-title">25%~75%</span><span class="is-value">'+two+' ~ '+seven+'</span></li>'+
							'</ul>' +
						'</div>' +
					'</div>';
				return res;
			}
		},
		xAxis: {
			name: name,
			nameLocation: 'start',
			nameTextStyle: {
				color: '#B7B7B7'
			},
			type: 'value',
			silent: true,
			position: 'top',
			splitLine: {
				show: true
			},
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		yAxis: [{
			type: 'category',
			data: Object.keys(data.item).map(function (key) {
				var str;
				switch(_this.chartType) {
					case 'month-chart':
						str = data.item[key][2];
						break;
					case 'year-chart':
						str = data.item[key][0];
						break;
					default: break;
				}
				return str;
			}).reverse(),
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				interval: 0,
				lineHeight: 200
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
			splitLine: {
				show: true
			},
			triggerEvent: true,
			offset: '10'
		}],
		series: [{
			name: '客戶落點',
			type: 'k',
			itemStyle: {
				normal: {
					color: 'rgba(65, 125, 187, 0.3)',
					color0: 'rgba(0,0,0,0)',
					borderColor: 'rgba(65, 125, 187, 0.8)',
					borderWidth: 1
				}
			},
			data: Object.keys(data.item).map(function (key) {
				var arr;

				switch(_this.chartType) {
					case 'month-chart':
						arr = [data.item[key][4], data.item[key][6],
									data.item[key][4], data.item[key][6] ]
						break;
					case 'year-chart':
						arr = [data.item[key][2], data.item[key][4],
									data.item[key][2], data.item[key][4] ]
						break;
					default: break;
				}
				return arr;
			}).reverse()
		},
		{
			name: '平均值',
			type: 'scatter',
			z: 2,
			itemStyle: {
				normal: {
					color: 'rgba(65, 125, 187, 1)'
				}
			},
			symbolSize: 10,
			data: Object.keys(data.item).map(function (key) {
				var str;
				switch(_this.chartType) {
					case 'month-chart':
						str = data.item[key][5];
						break;
					case 'year-chart':
						str = data.item[key][3];
						break;
					default: break;
				}
				return str;
			}).reverse()
		}]
	};

	if (option && typeof option === "object") {
		chartCanvas.setOption(option, true);

		$(window).on('resize', function(){
			if(chartCanvas != null && chartCanvas != undefined){
					chartCanvas.resize();
			}
		});
	}
}
Chart.prototype.generateYearList = function(data) {
	var list = $('.chart-list.is-yearList');
	var str = '';
	var month = (data.date[1]<10)? '0' + data.date[1] : data.date[1];
	var url = list.data('url');

	for (var i=0; i<data.item.length; i++) {
		str +=
			'<li>'+
				'<a class="icon is-yearChart" href="'+ url +
					'?type1=' + data.sub_category[0]+
					'&duration='+data.date[0]+'-'+month+
					'&campaign1='+data.campaign[0]+
					'&campaign2='+data.campaign[1]+
					'&tag='+data.item[i][1]+
				'">'+
					data.item[i][2].split('\n').join('')+' 年度趨勢'+
				'</a>'+
			'</li>';
	}

	list.html(str);
}
Chart.prototype.generateDistroChart = function(target, data) {
	var _this = this;
	target.prepend('<div id="chartDom"></div>');;
	var dom = target.find('#chartDom')[0];
	dom.style.minHeight = '400px';
	var chartCanvas = echarts.init(dom, null, {renderer: 'canvas'});
	var arr = [];
	var dataZoom = '';
	var height = '';
	var left = '';
	var width = '';

	if ($('#distroChart').length) {
		height = 'auto';
		left = '3%';
		width = '92%';
		dataZoom = [
			{
				type: 'inside',
				start: 0,
				end: 100,
			}, {
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			}
		];
	} else {
		width = '95%';
		left = 'center';
		height = '95%';
	}

	var option = {
		grid: [{
			top: '10',
			width: width,
			height: height,
			left: left,
			containLabel: true
		}],
		xAxis: {
			type: 'category',
			data: Object.keys(data.item).map(function (key) {
				var num = new Number(data.item[key][0]);
				if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
					num = num.toFixed(4) * 100;
				}
				num = num.toFixed(2);
				return num;
			}),
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0,
				formatter: function(value, index) {
					var str = '';
					if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
						str = value + '%';
					} else {
						str = '$' + value;
					}
					return str;
				}
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		yAxis: {
			type: 'value',
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0,
				formatter: function(value, index) {
					return value + '%';
				}
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		dataZoom,
		tooltip: {
			trigger: 'axis',
			padding: 0,
			backgroundColor: 'rgba(0,0,0,0)',
			borderRadius: '0',
			formatter: function(params, ticket, callback) {
				var title = params[0].name;
				var num = params[0].data;
				
				if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
					title = title + '%';
				} else {
					title = '$' + title;
				}

				var res =
					'<div class="chart-tooltip">'+
						'<div class="is-title">'+ title +'</div>' +
						'<div class="is-content">'+
							'<ul class="is-list">'+
								'<li><span class="is-value">'+ num +'%</span></li>'+
							'</ul>' +
						'</div>' +
					'</div>';
				return res;
			}
		},
		series: [{
			data: Object.keys(data.item).map(function (key) {
				var num = new Number(data.item[key][1]);
				num = num.toFixed(4) * 100;
				num = num.toFixed(2);
				return num;
			}),
			type: 'line',
			smooth: true,
			lineStyle: {
				color: 'rgb(65, 125, 187)',
				opacity: 0.7
			},
			itemStyle: {
				color: 'rgb(65, 125, 187)'
			},
		}]
	}

	if (option && typeof option === "object") {
		chartCanvas.setOption(option, true);

		$(window).on('resize', function(){
			if(chartCanvas != null && chartCanvas != undefined){
					chartCanvas.resize();
			}
		});
	}
}
Chart.prototype.generateDistroList = function(data) {
	var list = $('.chart-list.is-distro');
	var str = '';
	var year, month, label, title;

	for (var i=0; i<data.item.length; i++) {
		switch (this.chartType) {
			case 'month-chart':
				year = data.date[0];
				month = (data.date[1]<10)? '0'+data.date[1] : data.date[1];
				label = data.item[i][1];
				title = data.item[i][2].split('\n').join('');
				break;
			case 'year-chart':
				year = data.item[i][0].split('-')[0];
				month = data.item[i][0].split('-')[1];
				label = data.tag[0];
				title = data.tag[1];
				break;
			default:
				break;
		}

		str += '<li>'+
							'<button class="icon is-distroChart getDistro" '+
								'data-year="'+year+'" '+
								'data-month="'+month+'" '+
								'data-category="'+data.category[0]+'" '+
								'data-sub_category="'+data.sub_category[0]+'" '+
								'data-campaign1="'+data.campaign[0]+'" '+
								'data-campaign2="'+data.campaign[1]+'" '+
								'data-label="'+label+'">'+title+' 分配圖'+
							'</button>'+
						'</li>';
	}
	
	list.html(str);

	$.each($('button.getDistro'), function(){
		$(this).on('click', function(){
			fetchDistroData($(this), $(this).data());
		});
	});

	function fetchDistroData(target, data) {
		$('#myModal').modal('show');

		url =	target.parents('.chart-list').data('url') +
					'?duration='+data.year+'-'+data.month+
					'&sub_category='+data.sub_category+
					'&campaign1='+data.campaign1+
					'&campaign2='+data.campaign2+
					'&label='+data.label;

		$.get(url)
			.done(function(data){
				if (typeof data == "string") var data = $.parseJSON(data);

				$('.modal-title').text(
					data.category[1] +'-'+
					data.sub_category[1]+' '+
					data.campaign[0]+' '+
					data.campaign[1]+' '+
					data.tag[1]+' 分配圖 '+
					'('+data.date[0]+'/'+data.date[1]+')'
				);

				if(data.item !== null) {
					showDistroChart(data);
				} else {
					$('.modal-body').html('<h3>NO DATA</h3>');
				}
			})
			.fail(function(err){
				$('.modal-title').text('Error');
				$('.modal-body').html('<h3>NO DATA</h3>');
			});
	}

	function showDistroChart(data) {
		$('.modal-body').html('<div id="distroChart"></div>');
		var $distroChart = $('#distroChart');

		// if month chart insert report form
		if (chartData.type == 'month-chart') {
			var url = $('.wrapper > .report').find('[name="reportForm"]').attr('action');

			$('#distroChart').after(
				'<div class="report block is-grey">'+
					'<p class="block-title">獲取台北數位指數報告</p>'+
					'<form class="report-form" name="reportForm" method="POST" action="'+url+'">'+
						'<input class="report-input" type="email" name="email" value="" placeholder="請輸入您的E-mail">'+
						'<input class="report-button" type="submit" value="取得報告">'+
						'<input type="hidden" name="type1" value="'+data.sub_category[0]+'">'+
						'<input type="hidden" name="duration" value="'+data.date[0]+'-'+(data.date[1] < 10? '0'+data.date[1]: data.date[1]) +'">'+
						'<input type="hidden" name="campaign1" value="'+data.campaign[0]+'">'+
						'<input type="hidden" name="campaign2" value="'+data.campaign[1]+'">'+
						'<input type="hidden" name="tag" value="'+data.tag[0]+'">'+
					'</form>'+
				'</div>'
			);
	
			var $form = $('.modal [name="reportForm"]');
	
			$form.submit(function(event) {
				var $this = $(this);
				var $email = $this.find('[name="email"]');
				var val = $email.val();
				var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var msg = '';
				var err = 0;
		
				if(!val) {
					msg = '請輸入電子信箱';
					err += 1;
				} else if (!emailRE.test(val)) {
					msg = '請輸入正確格式電子信箱';
					err += 1;
				}
		
				if (err == 0) {
					// send request
					distroReportSubmit($form);
				} else {
					$email.addClass('is-error').val(msg);
	
					$email.focus(function() {
						$(this).removeClass('is-error').val('');
					});
				}
	
				return false;
			})
		}

		// generate distro chart
		var chart = new Chart('distroChart');
		chart.generateDistroChart($('#distroChart'), data);
	}

	function distroReportSubmit(target) {
		var msg = [];
		var url = target.attr('action');

		var data = {
			"email": target.find('[type="email"]').val()
		}
	
		target.serializeArray().map(function(v) {
			return data[v.name] = v.value;
		});

		console.log('分配圖 report data', data);

		$.ajax({
			url : url,
			data: data,
			type: 'GET',
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			beforeSend: function() {
				msg.push('資料傳送中');
				msg.push('資料傳送中，請稍後！');
				showModal(msg);
				msg = [];
			},
			error: function(xhr) {
				msg.push('Ajax request 發生錯誤');
				msg.push('Ajax request 發生錯誤，請稍後再試！');
				showModal(msg);
				msg = [];
			},
			success: function(result){
				if (result.result == '1') {
					msg.push('送信成功');
					msg.push('請到您輸入的信箱 <span>'+data.email+'</span> 收信<br>謝謝！');
				}
				if (result.result == '2') {
					msg.push('系統檔案生成中');
					msg.push('系統檔案生成中，我們將盡快產出寄到您輸入的信箱 <span>'+data.email+'</span> 中<br>謝謝！');
				}
				showModal(msg);
				msg = [];
			}
		});
	}
}
if (typeof chartData === "object") {
	var chart = new Chart('chart');
	chart.init(chartData);
	
	if (chartData.item.length == 0) {
		$('#chart').html('<h3>No Data</h3>');
	}
} else {
	if ($('.func').next().hasClass('chart')) {
		$('.func').hide();
	}
	$('#chart').html('<h3>No Data</h3>');
}
exports.modules = 'Chart';