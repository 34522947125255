var showError = function(target, msg) {

	if ($(target).next('span.form-error').length) {
		$(target).next('span.form-error').text(msg);
	} else {
		$(target).after('<span class="form-error">'+msg+'</span>');
	}

	if (msg == '') {
		$(target).next('.form-error').remove();
		$(target).find('.form-error').remove();
	}

}

module.exports = showError;