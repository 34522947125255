var contactSubmit = require('../modules/contactSubmit');
var reportSubmit = require('../modules/reportSubmit');
var showError = require('../modules/showError');
var showModal = require('../modules/showModal');

$('form').each(function() {
	var $this = $(this);
	var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	$this.on({
		'submit': function(event) {
			var err = 0;
			var formName = $this.attr('name');
			var formElement = 'input[type="tel"], input[type="text"], input[type="email"], textarea, select';
			
			$this.find(formElement).filter(':enabled').each(function() {
				var val = $(this).val();
				if (val == ''&& $(this).attr('id') !== 'position') err += 1;
				var msg = '';
			
				switch ($(this).attr('name')) {
					case 'name': if (!val) msg = '請輸入姓名'; break;
					case 'tel': if (!val) msg = '請輸入電話'; break;
					case 'email':
						if(!val) {
							msg = '請輸入電子信箱';
						} else if (!emailRE.test(val)) {
							msg = '請輸入正確格式電子信箱';
							err += 1;
						}
						break;
					case 'company': if (!val) msg = '請輸入公司名稱'; break;
					case 'p_type': if (!val) msg = '請輸入產業類別'; break;
					case 'comments': if (!val) msg = '請輸入詢問內容'; break;
					case 'verifyCode': if (!val) msg = '請輸入驗證碼'; break;
					case 'activeCode': if (!val) msg = '請輸入啟用碼'; break;
					case 'nickname': if (!val) msg = '請輸入暱稱'; break;
					case 'title': if (!val) msg = '請輸入文章主題'; break;
				}
				
				if (formName == 'reportForm') {
					$(this).focus(function() {
						$(this).removeClass('is-error').val('');
					})
					if (err !== 0) {
						$(this).addClass('is-error').val(msg);
					}
				} else {
					showError(formName, $(this), msg);
				}
			});
			
			$this.find('input[type="file"]').each(function(event) {
				var val = $(this).val().split( '\\' ).pop();
				var $display = $(this).next('label');
			
				if ($(this).data('required', true) && val == '') {
					err += 1
					msg = '請選擇上傳檔案';
					$display.addClass('is-error').text(msg);
				} else {
					$display.removeClass('is-error');
				}
			});

			if (err == 0) {
				switch ($this.attr('name')) {
					case 'reportForm':
						reportSubmit(event);
						break;

					case 'activeForm':
						activeSubmit(event);
						break;

					case 'memberForm':
						memberSubmit(event);
						break;

					// case 'query':
					// 	querySubmit(event);
					// 	break;

					default:
						return true;
						break;
				}
			}

			return false;
		},
		'reset': function() {
			$this.find('.form-error').remove();
			$this.find('label').removeClass('is-error');
			$this.find('input[type="file"]').next('label').text('選擇上傳檔案');
		}
	})
})

exports.modules = 'formVerify';