if ($('[name="searchForm"]').length) {
	$form = $('[name="searchForm"]');

	$datepicker.on('change', function() {
		dateArray = $(this).val().split('/');
		var $campaign1 = $form.find('[name="campaign1"]');
		var $campaign2 = $form.find('[name="campaign2"]');
    $form.find('[name="year"]').val(dateArray[0]);
		$form.find('[name="month"]').val(dateArray[1]);

		if ($campaign1.val() == '') {
			$campaign1.attr('disabled', true);
		} else {
			$campaign1.attr('disabled', false);
		}

		if ($campaign2.val() == '') {
			$campaign2.attr('disabled', true);
		} else {
			$campaign2.attr('disabled', false);
		}

    $form.submit();
	});
}

exports.modules = 'searchFormSubmit';