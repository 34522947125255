var showModal = function(msg) {
	var $modal = $('#myModal');
	var $modalTitle = $modal.find('.modal-title');
	var $modalBody = $modal.find('.modal-body');
	var content = '';
	$modalTitle.text(msg[0]);

	for (var i = 1;  i < msg.length; i++) {
		content += msg[i];
	}

	$modalBody.html(content);
	$modal.modal('show');
}

module.exports = showModal;