var showModal = require('./showModal');

var reportSubmit = function (event) {
	var $form = $('form[name="reportForm"]');
	var url = $form.attr('action');
	var msg = [];
	var data = {};

	$form.serializeArray().map(function(v) {
		return data[v.name] = v.value;
	})

	console.log('report data', data);

	$.ajax({
		url : url,
		data: data,
		type: 'GET',
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		beforeSend: function() {
			msg.push('資料傳送中');
			msg.push('資料傳送中，請稍後！');
			showModal(msg);
			msg = [];
		},
		error: function(xhr) {
			msg.push('Ajax request 發生錯誤');
			msg.push('Ajax request 發生錯誤，請稍後再試！');
			showModal(msg);
			msg = [];
		},
		success: function(result){
      if (result.result == '1') {
        msg.push('送信成功');
        msg.push('請到您輸入的信箱 <b class="text is-main">'+data.email+'</b> 收信<br>謝謝！');
      }
      if (result.result == '2') {
        msg.push('系統檔案生成中');
        msg.push('系統檔案生成中，我們將盡快產出寄到您輸入的信箱 <b class="text is-main">'+data.email+'</b> 中<br>謝謝！');
      }
			showModal(msg);
			msg = [];
		}
	});
}

module.exports = reportSubmit;