if ($('input[type="file"]').length) {
	$('input[type="file"]').each(function() {
		var $this = $(this);
		var labelText = '選擇上傳檔案';
		var $form = $this.parents('form');
		var $label = $this.next('label').text(labelText);

		$this.on({
			change: function(e) {
				var filename = '';

				if( this.files && this.files.length > 1 ) {
					filename = '已選擇 '+ $this[0].files.length + ' 個檔案';
				} else {
					filename = e.target.value.split( '\\' ).pop();
				}

				if (filename) {
					$label.text(filename);
				}

				if (this.files.length !== 0) {
					$label.removeClass('is-error');
				}
			}
		});
	});
}

exports.modules = 'inputFile';