if ($('form[name="query"]').length) {
	$queryForm = $('form[name="query"]');
	$input = $queryForm.find('input');
	$select = $queryForm.find('select');
	$button = $queryForm.find('button');

	$queryForm.find('input, select').val('').on('change keyup', function(e) {
		if ($input.val() !== '') {
			$select.attr('disabled', true);
		} else {
			$select.attr('disabled', false);
		}
		
		if ($select.val() !=='') {
			$input.attr('disabled', true);
		} else {
			$input.attr('disabled', false);
		}

		if ($input.val() !=='' || $select.val() !=='') {
			$button.attr('disabled', false);
		} else {
			$button.attr('disabled', true);
		}
	})

	$queryForm.submit(function(e) {
		if ($input.val() !=='' || $select.val() !=='') {
			if ($select.val() !=='') {
				return true;
			} else {
				competitorQuery();

				// 判斷競品關鍵字是否有數據 function
				function competitorQuery() {
					// 在api撈競品數量，query為競品數量
					var query = null;
					var url = './check.php?competitor='+ $input.val();
					
					$.get(url)
						.done(function(data) {
							query = parseInt(data);

							if (query !== 0) {
								// 有資料，跳轉頁面
								location.href = './'+ $queryForm.attr('action') +'?competitor=' + $input.val();
							} else {
								// 無資料，出現Popup No Data
								$('#myModal').modal('show');
								$('.modal-title').text('查無資料');
								$('.modal-body').html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 很抱歉，查無此客戶所屬產業分類，請重新查詢');
							}
						})
						.fail(function(data) {
							$('#myModal').modal('show');
							$('.modal-title').text('資料庫錯誤');
							$('.modal-body').html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 很抱歉，資料庫錯誤，請重新查詢');	
						})
				}
				return false;
			}
		} else {
			return false;
		}
	})
}

exports.modules = 'query';